import React from "react";

function SideBar() {
  return (
    <aside class="w-full md:w-1/3 flex flex-col items-center px-3">
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ዩቲዩብ ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ዩቲዩብ ቻናልና ይከታተሉ</p>
        <a
          href="https://www.youtube.com/@mahbereqdusyaredzeorthodox7025"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4" rel="noreferrer"
        >
          ዩቲዩብ ቻናል
        </a>
      </div>
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ፌስቡክ ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ፌስቡክ ገጽ ይከታተሉ</p>
        <a
          href="https://www.facebook.com/profile.php?id=100068679332973"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          ፌስቡክ ገጽ
        </a>
      </div>
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ቴሌግራም ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ቴሌግራም ቻናልና ይከታተሉ</p>
        <a
          href="https://web.telegram.org/a/#-1001314838089"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          ቴሌግራም ቻናል
        </a>
      </div>
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ WhatsApp ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ WhatsApp ምይይጥ ይግበሩ</p>
        <a
          href="https://chat.whatsapp.com/8kAEqF2Q5Fh56pzMLBa2Z7"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          WhatsApp ምይይጥ
        </a>
      </div>
    </aside>
  );
}

export default SideBar;
