import React, { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import MainSection from "./MainSection";
import EduArticle from "./DetailContent";
export var data = [];
export var id = 1;
const DropdownNav = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const menuRef = useRef(null);
  const submenuRef = useRef(null);

  const handleExistance = () => {
    if (menuRef.current.classList.contains("hidden")) {
      menuRef.current.classList.remove("hidden");
    } else {
      menuRef.current.classList.add("hidden");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admin.betyared.org/api/category/"
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryChange = async (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    try {
      const response = await fetch(
        `https://admin.betyared.org/api/regEdu/?type_id=${categoryId}`
      );
      const data = await response.json();
      setSubCategories(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <li className="group inline-block">
      <button
        type="button"
        id="burger"
        onClick={handleExistance}
        className="btn"
      >
        <span className="btn">ትምህርቲ ሃይማኖት</span>
        <span>
          <svg
            className="fill-current h-4 w-4 transform hover:text-primary group-hover:-rotate-180 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </button>
      <ul className=" bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50">
        <li
          ref={menuRef}
          className="rounded-sm relative px-3 py-1 hover:bg-gray-100"
        >
          {categories.map((category) => (
            <li className="px-3 py-1 hover:bg-primary hover:text-white">
              <button
                key={"cat" + category.id}
                type="button"
                onMouseEnter={handleCategoryChange}
                value={category.id}
                className="w-full text-left flex items-center outline-none focus:outline-none"
              >
                {category.name}
              </button>
            </li>
          ))}
          {selectedCategory && (
            <>
              <Routes>
                {subCategories.map((subCategory) => (
                  <Route
                    path={"/regEdus/" + subCategory.id}
                    exact
                    element={
                      <MainSection page={<EduArticle data={subCategory} />} />
                    }
                  />
                ))}
              </Routes>
              <ul
                className="bg-white border rounded-sm absolute top-0 left-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32"
                ref={submenuRef}
                id="submenu"
              >
                {subCategories.map((subCategory) => (
                  <li
                    className="px-3 py-1 hover:bg-primary hover:text-white"
                    key={"sub" + subCategory.id}
                  >
                    <a href={"/regEdus/" + subCategory.id}>
                      {subCategory.title}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      </ul>
    </li>
  );
};

export default DropdownNav;
