import React from "react";
import classes from "./Item.module.css";
import Card from "./ui/Card";
function ItemGedamat(props) {
  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.image}>
          <img src={props.image} alt={props.title} />
        </div>
        <div className={classes.content}>
          <h3>{props.name}</h3>
          <address>{props.address}</address>
          <p className="text-justify">{props.history}</p>
        </div>
      </Card>
    </li>
  );
}

export default ItemGedamat;
